import {useParams, Link} from 'react-router-dom'
import React, {useState, useEffect} from 'react'
import Title from './Title'

export default function LegoDetail() {
    let parms = useParams()

    let [legoList, setLegoList] = useState([])
    
    useEffect(()=>{
        fetch('../minifig.json')
        .then(response => response.json())
        .then(data => setLegoList(data))
    },[parms.id])
    
  return (
    <div className='LegoDetail'>
        <Title titleName={'Series ' + parms.id}></Title>
        
        <table width = "100%" border="1">                       
            <tbody>
                <tr>
                    <td>
                        {
                              
                        legoList.map(lego=>{
                            if (lego.series === parms.id){
                                {
                                    return lego.minifig.map(minifig=>{                
                                        return (
                                        <Link to={'/info/' + parms.id + '/' + minifig.number}>
                                            <img src={process.env.PUBLIC_URL+'/image/info/s' + parms.id + '/' +                                  
                                            minifig.info_img_name} alt={ minifig.info_img_name}></img>
                                        </Link>
                                        )
                                    })
                                }
                            }
                        })
                        }
                    </td>
                </tr>    
            </tbody>
        </table>
    </div>
  )
}
