import {Link} from 'react-router-dom'
import React, { useState, useEffect} from 'react'
import Title from './Title'

export default function LegoList() {
    let [legoList, setLegoList] = useState([])

    useEffect(()=>{
        fetch('../minifig.json')
        .then(response => response.json())
        .then(data => setLegoList(data))
        .catch(error => console.log(error))

    },[])
  return (
    <div>
        <Title titleName="Minifigure Collection"/>
        <table>
        {
            legoList.map(lego=>(
                
                <tr>
                    <td className='legoList'>
                        <Link to ={'/series/' + lego.series}>
                        <img src={process.env.PUBLIC_URL+'/image/cell/' + lego.cell_img_name} alt="lego 1"></img>
                        </Link>
                    </td>
                </tr>
            ))
        }
        </table>

{/*
        <table>
            <tr>
                <td className='legoList'>
                    <Link to ='/series/1'>
                    <img src={process.env.PUBLIC_URL+'/image/cell/' + 'series1_cell.jpg'} alt="lego 1"></img>
                    </Link>
                </td>
            </tr>
            <tr>
                <td className='legoList'>
                    <img src={process.env.PUBLIC_URL+'/image/cell/' + 'series2_cell.jpg'} alt="lego 2"></img>
                </td>
            </tr>
        </table>
  */}
    </div>
  )
}
