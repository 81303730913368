import React from 'react'
import BtnBackToMain from './BtnBackToMain'

export default function Ttitle({titleName}) {
  return (
    <div>
      <table width="100%">
        <tbody>
          <tr>
            <td className='title'><h1 >{titleName}</h1></td>
            <td className='backBtn'><BtnBackToMain/></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
