import {useParams, Link} from 'react-router-dom'
import React, {useState, useEffect} from 'react'
import Title from './Title'

export default function LegoInfo() {
    let [legoList, setLegoList] = useState([])
    let parms = useParams()

    useEffect(()=>{
        fetch('../../minifig.json')
        .then(response => response.json())
        .then(data => setLegoList(data))
    },[parms.id2])


  return (
    <div className='LegoDetail'>
        <Title titleName="Information"></Title>

    {
        (Object.keys(legoList).length > 0) &&
        <div>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <img src={process.env.PUBLIC_URL + '/image/info/' + 's'+ parms.id + '/' + legoList[parms.id -1].minifig[parms.id2 -1].info_img_name}></img>
                        </td>
                    </tr>
                </tbody>
            </table>

            <span>Name : {legoList[parms.id -1].minifig[parms.id2 -1].name} </span><br/>
            <span>Series : {legoList[parms.id-1].series_name}</span><br/>
            <span>Year : {legoList[parms.id-1].year_released}</span>
            </div>
            
    }
    </div>
  )
}
