import {Link} from 'react-router-dom'
import React from 'react'

export default function BtnBackToMain() {
  return (
    <div>
        <Link to ="/">
            <span className="backBtn" > Back </span>
        </Link>
    </div>
  )
}
