import {Router, Routes, Route, BrowserRouter} from 'react-router-dom'
import './App.css';
import LegoDetail from './LegoDetail';
import LegoInfo from './LegoInfo';
import LegoList from './LegoList';


function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<LegoList/>} />
          
          <Route path="/series" element={<LegoDetail/>}>
					  <Route path=":id" element={<LegoDetail/>} />
          </Route>

          <Route path="/info" element={<LegoInfo/>}>
          <Route path=":id/:id2" element={<LegoInfo/>} />
          </Route>
      </Routes>

    </BrowserRouter>
  );
}

export default App;

